$footer-section-margin: 8rem;

.footer {
  color: $white;

  background: url("img/footer-bg.jpg") no-repeat 50%;
  background-size: cover;

  h2 {
    margin-bottom: 1rem;

    @include breakpoint(medium up) {
      margin-bottom: 2rem;
    }
  }

  nav {
    width: 100%;
  }

  .color-bar {
    margin-bottom: 2rem;
  }
}

.footer__link {
  text-transform: uppercase;
  font-weight: bold;
}

// Footer overlay
// ==============

.footer__overlay {
  background-color: rgba(0, 0, 0, 0.7);
  padding-bottom: 4rem;

  @include breakpoint(medium up) {
    padding-bottom: 8rem;
  }
}

// Footer contact
// ==============

.footer__contact-details,
.footer__sponsors {
  margin-bottom: 2rem;

  a {
    color: $white;

    &:hover,
    &:focus {
      color: darken($white, 20%);
    }
  }
}

.footer__sponsors {
  margin-bottom: 1rem;

  .logo-ebgb {
    width: 100%;
    max-width: 18.75rem;
  }
}

// Footer navigation
// =================

.footer-nav-wrapper {
  @include flex-grid-row();
  @include flex-grid-layout(1, ".footer-nav");

  list-style-type: none;
  color: $white;
  font-weight: bold;

  @include breakpoint(medium up) {
    @include flex-grid-layout(2, ".footer-nav");
  }
}

.footer-nav {
  margin-bottom: 2rem;

  a {
    color: $white;

    &:hover,
    &:focus {
      color: darken($white, 20%);
    }
  }

  @include breakpoint(medium up) {
    margin-bottom: 0;
  }
}

.footer-nav__list {
  list-style-type: none;
  text-transform: uppercase;
}

.footer-nav__item {
  margin-bottom: 0.5rem;
}
