$border-width: 3rem;
$border-width-small: 2rem;

@mixin speech-bubble($small: false) {
  position: relative;
  padding: 1rem;
  margin-bottom: 1rem;

  background-color: $white;

  border-color: $white;
  border-radius: 0.75rem;

  &:after {
    content: "";
    position: absolute;
    display: block;
    width: 0;
    height: 0;

    border-style: solid;
  }
}

@mixin speech-bubble-arrow($direction, $small: false) {
  &:after {
    border-width: if($small, $border-width-small, $border-width);
  }

  // Position of the arrow
  // =====================

  @if $direction == top {
    margin-top: $border-width;

    &:after {
      // IE11: 0.5px is used to avoid the thin gap between the bubble and the arrow
      top: -($border-width-small - rem-calc(0.5));
      left: 50%;

      border-top-color: transparent;
      border-right-color: transparent;
      border-bottom-color: inherit;
      border-left-color: transparent;
      border-top-width: 0;

      transform: translate(-50%, 0);

      @include breakpoint(medium up) {
        // IE11: 0.5px is used to avoid the thin gap between the bubble and the arrow
        top: -(if($small, $border-width-small, $border-width) - rem-calc(0.5));
      }
    }
  }

  @if $direction == right {
    margin-right: $border-width;

    &:after {
      top: 50%;
      // IE11: 0.5px is used to avoid the thin gap between the bubble and the arrow
      right: -($border-width-small - rem-calc(0.5));

      border-top-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-left-color: inherit;
      border-right-width: 0;

      transform: translate(0, -50%);

      @include breakpoint(medium up) {
        // IE11: 0.5px is used to avoid the thin gap between the bubble and the arrow
        right: -(if($small, $border-width-small, $border-width) - rem-calc(0.5));
      }
    }
  }

  @if $direction == bottom {
    margin-bottom: $border-width;

    &:after {
      // IE11: 0.5px is used to avoid the thin gap between the bubble and the arrow
      bottom: -($border-width-small - rem-calc(0.5));
      left: 50%;

      border-top-color: inherit;
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-left-color: transparent;
      border-bottom-width: 0;

      transform: translate(-50%, 0);

      @include breakpoint(medium up) {
        // IE11: 0.5px is used to avoid the thin gap between the bubble and the arrow
        bottom: -(if($small, $border-width-small, $border-width) - rem-calc(0.5));
      }
    }
  }

  @if $direction == left {
    margin-left: $border-width;

    &:after {
      // IE11: 0.5px is used to avoid the thin gap between the bubble and the arrow
      left: -($border-width-small - rem-calc(0.5));
      top: 50%;

      border-top-color: transparent;
      border-right-color: inherit;
      border-bottom-color: transparent;
      border-left-color: transparent;
      border-left-width: 0;

      transform: translate(0, -50%);

      @include breakpoint(medium up) {
        // IE11: 0.5px is used to avoid the thin gap between the bubble and the arrow
        left: -(if($small, $border-width-small, $border-width) - rem-calc(0.5));
      }
    }
  }
}

// Create speech bubble classes for all directions and sizes

.speech-bubble {
  @include speech-bubble();

  &--top {
    @include speech-bubble-arrow(top);

    &--small {
      @include speech-bubble-arrow(top, true);
    }
  }

  &--right {
    @include speech-bubble-arrow(right);

    &--small {
      @include speech-bubble-arrow(right, true);
    }
  }

  &--bottom {
    @include speech-bubble-arrow(bottom);

    &--small {
      @include speech-bubble-arrow(bottom, true);
    }
  }

  &--left {
    @include speech-bubble-arrow(left);

    &--small {
      @include speech-bubble-arrow(left, true);
    }
  }
}
