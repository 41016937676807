// User notifications
// ==================

.notifications__title {
  @extend %text-center-small-only;

  margin-bottom: 3rem;
  font-size: rem-calc(map-get(map-get($responsive-sizes, small), h1));

  @include breakpoint(medium up) {
    font-size: rem-calc(map-get(map-get($responsive-sizes, medium), h1));
  }

  &--divider {
    padding-top: 2rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;

    @include breakpoint(medium up) {
      padding-top: 1rem;
    }
  }
}

.notifications__form {
  margin-bottom: 1rem;

  text-align: center;

  button {
    background-image: url(./img/envelope-open.svg);
    background-position: 1rem 50%;
    background-repeat: no-repeat;
    background-size: 1.2rem;
    padding-left: 3.2rem;
  }

  @include breakpoint(medium up) {
    text-align: right;
  }
}

.notifications__list {
  list-style-type: none;
  margin-bottom: 1rem;
}

.notification {
  background-color: $white;
  padding: 1rem;
  border-top: $nav-item-border-width solid $black;

  &:last-child {
    border-bottom: $nav-item-border-width solid $black;
  }
}

.notifications__empty {
  text-align: center;

  @include breakpoint(medium up) {
    text-align: left;
  }
}

.notification__header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 0.5rem;

  line-height: 1.2;

  .notification--short & {
    margin-bottom: 0;
  }

  .icon-link {
    display: block;
  }
}

.notification__buttons {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  margin-left: 0.5rem;

  a {
    margin-right: 0.5rem;

    font-family: $changa;
    font-weight: 700;
    line-height: inherit;
  }
}

.notification__title {
  margin-bottom: 0;

  font-size: $body-font-size;
  line-height: inherit;

  @include breakpoint(medium up) {
    font-size: $body-font-size-medium;
  }
}

.notification__image {
  padding-top: 0.3rem;
}
