$changa: "Changa", sans-serif;
$roboto: "Roboto", sans-serif;
$ms-emoji: "Segoe UI Emoji";

$body-font-size: 1rem;
$body-font-size-medium: 1.125rem;

$heading-line-height: 1;
$paragraph-line-height: 1.4;

$responsive-sizes: (
  small: (
    "h1": 30,
    "h2": 24,
    "h3": 22,
    "h4": 17,
  ),
  medium: (
    "h1": 48,
    "h2": 40,
    "h3": 31,
    "h4": 25,
  ),
);

@font-face {
  font-family: "Changa";
  font-style: normal;
  font-weight: 700;
  src: url(./fonts/Changa-700.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/Roboto-400.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url(./fonts/Roboto-700.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// Heading sizes
@each $size, $selector in $responsive-sizes {
  @include breakpoint($size) {
    @each $selector, $font-size in $selector {
      #{$selector} {
        font-size: rem-calc($font-size);
      }
    }
  }
}

body {
  font-family: $roboto;
  font-size: $body-font-size;
  line-height: $paragraph-line-height;
  color: $black;

  @include breakpoint(medium up) {
    font-size: $body-font-size-medium;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1rem;
  line-height: $heading-line-height;
  font-family: $changa;
}

small {
  font-size: 70%;
}

input,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: $black;
}

p {
  margin-bottom: 1rem;
}

a {
  color: $tawny-port;

  &:hover,
  &:focus {
    color: lighten($tawny-port, 20%);
  }
}
.content-richtext a {
  color: #c76a6a;
}

%text-center-small-only {
  text-align: center;

  @include breakpoint(medium up) {
    text-align: left;
  }
}

.content-richtext {
  ul,
  ol {
    padding-left: 1.25rem;
    margin-bottom: 1rem;
  }

  h2 {
    font-size: rem-calc(21);
    margin-top: 2rem;

    @include breakpoint(medium up) {
      font-size: rem-calc(25);
    }
  }

  h3 {
    font-size: rem-calc($body-font-size-medium);
    line-height: 1.2;

    @include breakpoint(medium up) {
      font-size: rem-calc(20);
      line-height: $heading-line-height;
    }
  }

  blockquote {
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;

    border-left: 0.25rem solid $dove-gray;
    background-color: $white;

    font-size: 1rem;
    font-style: italic;

    > :last-child {
      margin-bottom: 0;
    }
  }
}

.emoji {
  font-family: $ms-emoji;
}

%hyphenate {
  word-break: break-word;
  hyphens: auto;
}
$responsive-embed-margin-bottom: rem-calc(10);
$responsive-embed-ratios: (
  default: 4 by 3,
  widescreen: 15 by 9,
);
