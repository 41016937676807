$message-arrow-width: 1rem;
$message-arrow-height: 1.25rem;

$conversation-header-height: rem-calc(42);

// Create conversation form
// ========================

.conversations__add {
  text-align: center;
}

.conversations__add-form {
  margin-bottom: 1rem;
  text-align: left;

  textarea {
    border: rem-calc(3) solid $black;
    border-radius: 0.25rem;

    font-size: 0.9rem;

    height: 4.25rem;
    padding: 0.25rem;
  }

  &[aria-hidden="true"] {
    display: none;
  }

  .errorlist {
    &[aria-hidden="true"] {
      display: none;
    }
  }
}

.conversations__add-form-toggle {
  margin-bottom: 1rem;
}

// Wrapper
// =======

.messenger {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;

  height: 30rem;
  border: 3px solid $black;

  &.empty {
    border: none;
    text-align: center;

    padding-top: 1rem;
  }
}

// Conversation list
// =================

.conversation-tab-list {
  width: 100%;
  list-style-type: none;

  overflow: auto;

  @include breakpoint(medium up) {
    flex: 0 0 calc(4 * 100% / 12);
    max-width: calc(4 * 100% / 12);
    border-right: 3px solid $black;
  }
}

.conversation-tab-list__item {
  border-bottom: 1px solid $silver;
}

.conversation-tab-list__link {
  position: relative;

  display: block;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;

  color: $black;

  &[aria-selected="true"],
  &:hover {
    background-color: $white;
  }

  &.read {
    .conversation-tab-list__count {
      opacity: 0;
    }
  }
}

.conversation-tab-list__header {
  display: flex;
  justify-content: space-between;
}

.conversation-tab-list__user {
  font-family: $changa;
  font-weight: 700;
}

.conversation-tab-list__time {
  font-size: 0.9rem;
}

.conversation-tab-list__count {
  opacity: 1;
  position: absolute;
  right: 0.5rem;
  top: 2rem;

  min-width: 1.5rem;
  min-height: 1.5rem;

  padding: 0 0.25rem;

  background-color: $tawny-port;
  border-radius: 1.5rem;

  color: $white;
  font-family: $changa;
  text-align: center;

  transition: opacity 0.3s ease-in;
  transition-delay: 1.5s;
}

.conversation-tab-list__message {
  margin-bottom: 0;
  margin-right: 3.5rem;

  font-size: 1rem;
}

// Conversation wrapper
// ====================

.conversation {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  padding-top: $conversation-header-height;

  flex-grow: 1;

  display: flex;
  flex-direction: column;

  background-color: $white;
  opacity: 1;
  transform: translateX(0%);
  transition:
    transform 0.3s ease-in,
    opacity 0.5s ease-in-out;
  will-change: transform, opacity;

  &[aria-hidden="true"] {
    transform: translateX(100%);
    opacity: 0;
  }

  @include breakpoint(medium up) {
    position: relative;
    width: auto;

    flex-basis: calc(8 * 100% / 12);

    opacity: 1;

    &[aria-hidden="true"] {
      display: none;
    }
  }
}

.conversation__header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  display: flex;
  align-items: center;

  width: 100%;
  height: rem-calc(42);
  padding: 0.5rem 1rem 0.5rem 0.75rem;

  background-color: $alabaster;
  box-shadow: 0 0 4px 0 $black;
}

.conversation__back-button {
  height: 1rem;
  width: 1rem;

  margin-right: 1rem;

  background: url("./img/arrow-black.svg") no-repeat center / contain;
  transform: rotate(90deg);

  @include breakpoint(medium up) {
    display: none;
  }
}

.conversation__user {
  font-family: $changa;
  font-weight: 700;
}

.conversation__message-list-wrapper {
  height: 100%;
  flex-grow: 1;

  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.conversation__message-list {
  display: flex;
  flex-flow: column-reverse nowrap;
  // transform: scaleY(-1);

  min-height: 100%;
  margin: 0;

  list-style-type: none;
  background-color: $white;
}

@keyframes message-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.conversation__footer {
  padding: 0.5rem;
}

.conversation__footer-form {
  display: flex;
  align-items: center;

  .disable & {
    display: none;
  }
}

.conversation__footer-message {
  display: none;

  p {
    margin-bottom: 0;
    text-align: center;
  }

  .disable & {
    display: block;
  }
}

.conversation__textarea {
  display: block;
  resize: none;

  flex: 1;

  border: 1px solid $black;
  border-radius: 0.25rem;

  font-size: 1rem;

  height: 2rem;
  max-height: 4.25rem;
  padding: 0.25rem;
  margin: 0.25rem 0;
}

.conversation__send-button {
  width: 1.75rem;
  height: 1.75rem;
  margin-left: 0.5rem;

  border-radius: 0.25rem;
  background: url("./img/arrow.svg") 30% 50% / 75% $black no-repeat;
  background-color: $black;

  &:hover,
  &:focus {
    opacity: 0.7;
  }

  &[disabled] {
    background-color: lighten($black, 50%);
    cursor: default;
  }
}

.conversation__next-page-button {
  margin: 0.5rem 0.25rem 0.25rem 0.25rem;

  &[aria-hidden="true"] {
    display: none;
  }
}

// Message
// =======

.message {
  // flex: 0 0;
  display: block;
  // transform: scaleY(-1);
  padding: 0.5rem 0.5rem;

  &--sender {
    text-align: right;
  }
}

.message__content {
  position: relative;
  display: inline-block;

  min-width: 10rem;
  max-width: 70%;
  padding: 0.25rem 0.5rem;

  border-radius: 0.25rem;
  background-color: $silver;

  text-align: left;

  animation: message-fade-in 0.5s;
  will-change: opacity;

  &::before {
    content: "";
    display: block;
    position: absolute;

    right: -$message-arrow-width;
    bottom: 0.5rem;

    border-width: $message-arrow-height 0 0 $message-arrow-width;
    border-color: transparent transparent transparent $silver;
    border-style: solid;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    right: -$message-arrow-width * 2;
    bottom: 0.65rem;

    height: $message-arrow-width * 2;
    width: $message-arrow-width * 2;
    border-radius: 50%;

    background-color: $white;
  }

  .message--sender & {
    &::before {
      right: auto;
      left: -$message-arrow-width;
      border-width: $message-arrow-height $message-arrow-width 0 0;
      border-color: transparent $silver transparent transparent;
    }

    &::after {
      right: auto;
      left: -$message-arrow-width * 2;
    }
  }

  p {
    @extend %hyphenate;
    overflow: hidden;
    margin-bottom: 0.5rem;

    font-size: 1rem;
    white-space: pre-wrap;
  }
}

.message__time {
  display: block;
  font-size: 0.8rem;
  text-align: right;
}

// Info
// ====

.info {
  text-align: center;
  // transform: scaleY(-1);
}

.info__content {
  display: inline-block;
  border-radius: 0.25rem;

  padding: 0.25rem;

  background-color: $black;
  color: $white;

  animation: message-fade-in 1s;
}
