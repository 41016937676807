$register-link-border-width: rem-calc(4px);
$notification-length: 1.5rem;
$navigation-item-margin: 0.15rem;
$search-form-height: 2.25rem;
$link-border-radius: rem-calc(4px);

// Navigation Toggle
$icon-height: 1.5rem;
$icon-width: 2rem;
$icon-margin: 0.5rem;
$stroke-height: 0.25rem;
$stroke-width: 100%;

.header {
  min-height: 15rem;
  display: flex;
  flex-direction: column;

  // Speech Bubble
  // =============

  .speech-bubble {
    margin-top: 10rem;
    h1 {
      font-size: 1.3rem;
      @include breakpoint(medium up) {
        font-size: 1.875rem;
      }
    }
    p {
      color: $dove-gray;
      margin-bottom: 0;
    }

    @include breakpoint(medium) {
      margin-top: 15rem;
    }
    @include breakpoint(large) {
      margin-top: 20rem;
    }
  }

  @include breakpoint(medium up) {
    min-height: 30rem;
  }

  @include breakpoint(large up) {
    min-height: 38rem;
  }

  @include breakpoint(xxlarge up) {
    min-height: 42rem;
  }
}

.header__content {
  position: relative;
  flex: 1;
}

.header__background {
  position: absolute;
  z-index: 0;

  height: 100%;
  width: 100%;

  background: url("img/header-bg-s.jpg") no-repeat center;
  background-size: cover;

  @include breakpoint(large up) {
    background-image: url("img/header-bg.jpg");
    background-position: center 40%;
  }

  .no-mood & {
    // sorry! but we need to override inline styles
    background: none !important;
  }
}

.no-mood .header {
  min-height: 10rem;

  @include breakpoint(medium up) {
    min-height: 12rem;
  }
}

// Skip links
// ==========

.skip-links {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;

  z-index: 100;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);

  a {
    color: $white;
    font-family: $changa;
  }
}

// Top Bar
// =======

.top-bar {
  position: relative;
  z-index: 1;

  height: 4.5rem;
  padding: 1rem 0;

  font-size: 1rem;
  font-family: $changa;
  font-weight: 700;

  background-color: $black;

  @include breakpoint(large up) {
    padding: 1rem 0 0 0;
    height: auto;
  }
}

.top-bar__account {
  display: flex;
  flex-flow: row wrap;
}

.top-bar__profile,
.top-bar__register {
  position: relative;
  display: inline-block;
  height: 2.25rem;
  padding: 0 (2rem - $register-link-border-width);

  border-width: $register-link-border-width;
  border-top: solid $mandy;
  border-right: solid $rio-grande;
  border-bottom: solid $scooter;
  border-left: solid $fuel-yellow;
  border-radius: $link-border-radius;

  background-color: $black;
  color: $white;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;

  flex-grow: 1;

  &:hover,
  &:focus {
    background-color: lighten($black, 20%);
    color: $white;
  }
}

.top-bar__profile {
  padding: 0 1.25rem;

  img {
    width: 1.2rem;
    margin-right: 0.5rem;
  }

  &--notification {
    &:after {
      content: attr(data-notification);

      position: absolute;
      top: calc(-1 * $notification-length / 2);
      right: calc(-1 * $notification-length / 2);
      width: $notification-length;
      height: $notification-length;

      border-radius: 50%;
      background: #ff5c5c;
      box-shadow: 0 0px 4px 2px #ff5c5c;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.top-bar__logout {
  display: flex;
  align-items: center;

  padding-left: 0.3rem;
  margin-left: 0.5rem;
  width: $search-form-height;
}

// Logo
// ====

.logo {
  position: relative;
  margin-top: -1rem;

  img {
    width: 100%;

    @include breakpoint(large up) {
      width: 144px;
    }
  }
}

.logo__background {
  padding: 1.2rem 0.75rem 1rem;
  position: absolute;
  background-color: $white;
  border-radius: 0 0 0.5rem 0.5rem;

  max-width: rem-calc(96);

  @include breakpoint(medium up) {
    max-width: none;
    border-radius: 0 0 1rem 1rem;
  }
}

// Main Navigation
// ===============

.nav-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease;

  background: transparentize($black, 0.1);

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  align-self: center;

  padding-top: 3rem;

  .open-nav & {
    visibility: visible;
    opacity: 1;
  }

  @include breakpoint(large up) {
    visibility: visible;
    position: static;
    width: auto;
    height: auto;
    z-index: auto;
    overflow: visible;
    -webkit-overflow-scrolling: auto;

    padding-top: 0;

    opacity: 1;
    background-color: transparent;

    align-items: stretch;
    justify-content: center;
  }
}

.meta-nav {
  text-align: center;
  margin: 1rem 0;

  @include breakpoint(large up) {
    @include flex;
    @include flex-align(right);

    text-align: left;
    margin: 0;
  }
}

.main-nav {
  width: 100%;

  @include breakpoint(large up) {
    width: auto;
  }
}

.main-nav__list {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;

  height: 100%;
  padding: 0 $navigation-item-margin;

  list-style-type: none;
  text-align: center;

  @include breakpoint(large up) {
    height: auto;
    margin: 1rem 0;
    text-align: left;

    flex-direction: row;
    justify-content: flex-end;
  }
}

.main-nav__item {
  margin: 0.5rem 0;

  a {
    display: inline-block;
    padding: 0.5rem 1rem;

    border-radius: $link-border-radius;

    background-color: $black;
    color: $white;
  }

  @each $category, $color in $category-colors {
    $i: index(($category-colors), ($category $color));

    &:nth-child(#{$i}) {
      a {
        background-color: $color;

        &:hover,
        &:focus {
          background-color: lighten($color, 10%);
        }
      }
    }
  }

  @include breakpoint(large up) {
    margin: 0 $navigation-item-margin 0.25rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

.nav-toggle {
  position: relative;
  width: $icon-width;
  height: $icon-height;
  margin: $icon-margin;

  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;

  z-index: 1100;

  .stroke {
    position: absolute;
    left: 0;

    display: block;
    height: $stroke-height;
    width: 100%;

    background-color: $white;
    opacity: 1;

    transform: rotate(0deg);
    transition: 0.25s ease-in-out;

    &:nth-child(1) {
      top: 0;
      transform-origin: left center;
    }

    &:nth-child(2) {
      top: calc($icon-height / 2 - $stroke-height / 2);
      transform-origin: left center;
    }

    &:nth-child(3) {
      top: $icon-height - $stroke-height;
      transform-origin: left center;
    }

    .open-nav & {
      &:nth-child(1) {
        transform: rotate(45deg);
        top: -2px;
        left: 0px;
      }

      &:nth-child(2) {
        width: 0%;
        opacity: 0;
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
        top: 1.3125rem;
        left: 0px;
      }
    }
  }

  .open-nav & {
    position: fixed;
    right: calc(map-get($grid-column-gutter, small) / 2);
  }

  @include breakpoint(large up) {
    display: none;
  }
}

// Search Form
// ===========

.search-form {
  @include clearfix;

  margin: 0 0 1rem 0;

  @include breakpoint(large up) {
    margin: 0;
  }
}

.search-form__input {
  float: left;
  display: inline-block;
  height: $search-form-height;

  padding: 0 0.5rem;
  margin: 0;

  border-radius: $link-border-radius 0 0 $link-border-radius;
  border: none;

  line-height: normal;
}

.search-form__submit {
  display: inline-block;
  height: $search-form-height;
  width: $search-form-height;
  padding: 0;

  float: left;

  background-image: url(./img/search_icon_black.svg);
  background-position: center;
  background-color: $white;

  border-radius: 0 $link-border-radius $link-border-radius 0;

  img {
    width: 100%;
    height: 100%;
    transition: opacity 0.15s ease;
  }

  &:hover,
  &:focus {
    img {
      opacity: 0.5;
    }
  }

  @include breakpoint(large up) {
    margin-right: 1rem;
  }
}
