/* http://jaydenseric.com/blog/forget-normalize-or-resets-lay-your-own-css-foundation */
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

*,
*:after,
*:before {
  box-sizing: inherit;
}

body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font: 16px/1 sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
p,
blockquote,
figure,
ol,
ul {
  margin: 0;
  padding: 0;
}

main {
  display: block;
}

h1,
h2,
h3,
h4 {
  font-size: inherit;
}

strong {
  font-weight: bold;
}

a,
button {
  color: inherit;
  transition:
    color 0.3s,
    background-color 0.3s;
}

a {
  text-decoration: none;
}

button {
  overflow: visible;
  border: 0;
  font: inherit;
  -webkit-font-smoothing: inherit;
  letter-spacing: inherit;
  background: none;
  cursor: pointer;
}

img {
  max-width: 100%;
  height: auto;
  border: 0;
}
