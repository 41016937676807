// Thread
// =======

.thread {
  .boxed {
    font-size: 0.9rem;
    padding: 0.1rem 0.25rem;

    @include breakpoint(medium up) {
      font-size: 1rem;
      padding: 0.5rem 1rem;
    }
  }

  .adminlink {
    font-size: 0.8rem;
  }
}

.thread__meta {
  margin-bottom: 1rem;
  font-family: $changa;
  font-weight: 700;
  font-size: 1rem;

  @include breakpoint(medium up) {
    font-size: 1.25rem;
  }
}

.thread__title-wrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  margin-bottom: 2rem;

  @include breakpoint(medium up) {
    margin-bottom: 4rem;
  }
}

.thread__title {
  max-width: calc(100% - 2rem);
  font-size: 1.5rem;

  margin-bottom: 0;
  padding-right: 1rem;

  @include breakpoint(medium up) {
    font-size: 2.5rem;
  }
}

.thread__subscribe {
  display: inline-block;
  margin: 0;
  padding: 0;

  input[type="checkbox"] {
    @extend %visuallyhidden;
  }

  label {
    position: relative;

    font-size: 1.4rem;
    line-height: 1;
    color: lighten($black, 50%);
    transition: color 0.1s ease;

    cursor: pointer;

    &:before {
      content: "\2605";
      line-height: 1;
    }

    &:after {
      content: "Abonnieren";

      position: absolute;
      top: 0;
      left: 50%;

      padding: 0.25rem;

      color: $white;
      font-size: 1rem;

      background-color: $black;
      border-radius: 0.25rem;

      opacity: 0;
      transform: translate(-50%, -2rem);

      transition:
        transform 0.3s ease,
        opacity 0.3s ease;
    }

    &:hover,
    &:focus {
      color: $golden-dream;
    }

    &:hover {
      &:after {
        opacity: 1;
        transform: translate(-50%, -1rem);
      }
    }

    @include breakpoint(medium up) {
      font-size: 2rem;
    }
  }

  input:checked ~ label {
    color: $golden-dream;

    &:after {
      content: "Abonniert!";
    }
  }

  input:focus ~ label {
    @extend %focus-outline;
  }
}

.thread__question {
  background-color: $white;
  padding-bottom: 1rem;

  @include breakpoint(medium up) {
    background-color: transparent;
  }
}

.thread__question-footer {
  .thread__link-group {
    text-align: right;
    margin-bottom: 1rem;
  }
}

.thread__add {
  text-align: center;

  > .boxed {
    margin-bottom: 2rem;
  }

  .thread__post-form {
    text-align: left;
  }
}

// Author
// =======

.author {
  display: flex;
  flex-flow: row nonewrap;
  align-items: center;

  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 0rem;
  background-color: $white;

  font-size: 0.9rem;

  @include breakpoint(medium up) {
    display: block;

    background-color: transparent;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 1rem;

    font-size: inherit;

    &--comment {
      display: flex;
      padding-top: 1rem;
      padding-bottom: 1rem;
      margin-bottom: 0rem;
    }
  }
}

.author__image {
  max-width: 2.5rem;
  height: 2.5rem;

  @include breakpoint(medium up) {
    max-width: 9.3rem;
    height: auto;
  }

  // Comment styling
  .answer--comment & {
    max-width: 2.5rem;
    height: 2.5rem;
  }
}

.author__information {
  flex-grow: 1;
  padding: 0 0.5rem;

  @include breakpoint(medium up) {
    width: auto;
    padding: 0;
  }

  // Comment styling
  .answer--comment & {
    padding: 0 0.5rem;
  }
}

.author__name {
  font-family: $changa;
  font-weight: 700;
  font-size: 1rem;

  @include breakpoint(medium up) {
    display: block;

    font-size: 1.5rem;
  }

  @include breakpoint(large up) {
    font-size: 1.75rem;
  }

  // Comment styling
  .answer--comment & {
    display: inline;
    font-size: 1rem;
    margin-bottom: 0;

    @include breakpoint(medium up) {
      font-size: inherit;
    }
  }
}

.author__meta {
  display: flex;
  flex-flow: wrap row;
  margin: 0;

  dt {
    margin: 0;
    font-weight: 700;

    &:after {
      content: ":";
    }

    @include breakpoint(small only) {
      @include visuallyhidden;
    }
  }

  dd {
    display: inline-block;
    margin: 0;

    &:after {
      content: ",\00a0";
    }

    @include breakpoint(medium up) {
      &:after {
        content: "";
      }
    }

    &.author__member-type {
      margin-right: 0.25rem;

      @include breakpoint(medium up) {
        margin-bottom: 1rem;
        margin-right: 0;
      }

      &::after {
        content: none;
      }
    }

    // Comment styling
    .answer--comment & {
      &:after {
        content: ",\00a0";
      }

      &.author__member-type {
        margin-bottom: 0;
        margin-right: 0.25rem;

        &:after {
          content: none;
        }
      }
    }
  }

  @include breakpoint(medium up) {
    margin-bottom: 1rem;

    & > div {
      flex-basis: 100%;
    }
  }

  // Comment styling
  .answer--comment & {
    margin-bottom: 0;
    font-size: 0.9rem;

    & > div {
      flex-basis: auto;
    }

    @include breakpoint(medium up) {
      font-size: 1rem;
    }
  }
}

.author__row {
  display: inline;

  &:last-child {
    dd {
      &:after {
        content: none;
      }
    }
  }

  @include breakpoint(medium up) {
    display: block;
  }
}

.author__member-type {
  display: inline-block;
  background-color: $tawny-port;

  color: $white;
  font-family: $changa;
  font-weight: 700;
  font-size: 0.9rem;

  padding: 0 0.25rem;

  @include breakpoint(medium up) {
    padding: 0 0.5rem;
  }
}

.author__send-request {
  .label {
    // font-style: italic;
    font-size: 0.9rem;
    font-family: $changa;
    font-weight: 700;
    font-size: 0.9rem;

    padding: 0 0.5rem;

    background-color: $tawny-port;
    color: $white;
  }

  & > * {
    display: none;
  }

  &.not-friend {
    & > form {
      display: block;
    }
  }

  &.is-friend {
    & > .icon-link--message {
      display: inline-block;
    }
  }

  &.fail {
    .errorlist {
      display: block;
      padding: 0.5rem;
      margin-top: 0.5rem;
      font-size: 0.9rem;
    }
  }

  &.sent-request {
    & > .icon--sent {
      display: inline-block;
    }
  }

  @include breakpoint(medium up) {
    &.sent-request {
      & > .label--sent {
        display: inline-block;
      }

      & > .icon--sent {
        display: none;
      }
    }

    &.received-request {
      & > .label--received {
        display: inline-block;
      }
    }
  }

  .answer--comment & {
    &.sent-request {
      & > .icon--sent {
        display: inline-block;
      }
    }
  }
}

// Answers List
// ============

.answers {
  padding-top: 2rem;

  @include breakpoint(medium up) {
    padding-top: 4rem;
  }
}

.answers__header {
  display: flex;
  flex-flow: column wrap;
  align-items: center;

  padding: 0.6rem 0 0.9rem;
  margin-bottom: 2rem;

  border-top: rem-calc(2px) solid $tawny-port;
  border-bottom: rem-calc(1px) solid $black;

  @include breakpoint(medium up) {
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;

    padding: 2rem 0;
  }
}

.answers__title {
  margin-bottom: 0.5rem;
  text-align: center;
  font-size: 1.25rem;

  @include breakpoint(medium up) {
    margin-bottom: 0;
    text-align: left;
    font-size: 2rem;
  }
}

.answers__sort-info {
  display: block;
  font-size: $body-font-size;

  @include breakpoint(large up) {
    display: inline;
  }
}

// Answer
// ======

.answer {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: rem-calc(1px) solid $black;

  &--comment {
    border-bottom: 0;
    border-left: rem-calc(4px) solid $silver;

    background-color: $white;

    margin-bottom: 1rem;
    padding-bottom: 0.01rem;

    padding-left: calc(map-get($grid-column-gutter, small) / 2);
    padding-right: calc(map-get($grid-column-gutter, small) / 2);

    blockquote {
      background-color: $alabaster;
    }

    @include breakpoint(medium up) {
      padding-left: calc(map-get($grid-column-gutter, small) / 2);
      padding-right: calc(map-get($grid-column-gutter, small) / 2);
    }
  }
}

.answer__header {
  margin-bottom: 1rem;

  // Comment styling
  .answer--comment & {
    margin-bottom: 0;
  }
}

.answer__time {
  font-family: $changa;
  font-weight: 700;
  font-size: 1rem;

  @include breakpoint(medium up) {
    font-size: 1.25rem;
  }

  // Comment styling
  .answer--comment & {
    display: inline;
    font-size: 1rem;

    @include breakpoint(medium up) {
      font-size: inherit;
    }
  }
}

.answer__content {
  background-color: $white;
  padding-bottom: 1rem;

  @include breakpoint(medium up) {
    background-color: transparent;
  }

  // Comment styling

  .answer--comment & {
    padding-bottom: 0;
  }
}

.answer__footer .thread__link-group {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-flow: row wrap;

  margin-bottom: 1rem;

  .boxed {
    margin-right: 0.25rem;
    margin-bottom: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

.answer__rating {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;

  font-family: $changa;
  font-weight: 700;
}

// Comments
// ========

.comments {
  @include breakpoint(medium up) {
    padding-top: 2rem;
  }
}

.comments__header {
  margin: 1rem 0;

  // @include breakpoint(medium up) {
  //   @include visuallyhidden;
  //   margin: 0;
  // }
}

.comments__title {
  margin-bottom: 0;
}
