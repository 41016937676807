// Post filter
// ===========

.user-post__filter-list {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;

  list-style-type: none;

  margin: 0 0 1rem 0;

  @include breakpoint(medium up) {
    flex-flow: row wrap;
  }
}

.user-post__filter {
  width: 100%;
  padding: 0 0.25rem;
  margin-bottom: 0.5rem;

  font-family: $changa;
  font-weight: 700;

  a {
    display: inline-block;
    width: 100%;
    padding: 0.25rem 0.5rem;
    background-color: $black;
    border-radius: 0.25rem;
    color: $white;
    text-align: center;

    &:hover {
      background-color: $tawny-port;
    }
  }

  &.active {
    a {
      background-color: $tawny-port;
    }
  }

  @include breakpoint(medium up) {
    width: auto;
  }
}

// Post list
// =========

.user-post__list {
  list-style-type: none;
  border-radius: 0.25rem;
}

.user-post__empty {
  text-align: center;
}

// Post item
// =========

.user-post__item {
  padding: 1rem;
  background-color: $white;

  border-bottom: 3px solid $black;

  &:nth-child(2n) {
    background-color: transparent;
  }

  &:first-child {
    border-top: 3px solid black;
  }
}

.user-post__header {
  display: flex;
  flex-direction: column-reverse;

  @include breakpoint(medium up) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.user-post__title {
  font-size: $body-font-size;
  line-height: 1.3;

  @include breakpoint(medium up) {
    font-size: $body-font-size-medium;
  }
}

.user-post__forum-label {
  display: inline-block;

  color: $white;
  font-family: $changa;
  font-weight: 700;
  font-size: 0.9rem;

  padding: 0.25rem;
  margin-bottom: 0.5rem;

  @each $category, $color in $category-colors {
    &--#{$category} {
      background-color: $color;
    }
  }

  @include breakpoint(medium up) {
    padding: 0.25rem 0.5rem;
    margin-bottom: 0;
  }
}

.user-post__content {
  > *:last-child {
    margin-bottom: 0;
  }
}
