$messages-border-radius: 0.25rem;

// Messages
// ========

.sys-messages {
  margin: 1rem 0 3rem;
  list-style-type: none;

  &.nonfield {
    margin-bottom: 1rem;
  }

  @include breakpoint(medium up) {
    margin-bottom: 5rem;
  }
}

.sys-messages__item {
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  border-radius: $messages-border-radius;
  padding: 0.5rem 1rem;
  margin-bottom: 0.25rem;

  color: $black;

  &:last-child {
    margin-bottom: 0;
  }

  &:before {
    content: "";
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 1rem;

    background-repeat: no-repeat;
    background-size: contain;
  }

  &.success {
    background-color: map-get($foundation-palette, success);

    &:before {
      background-image: url("./img/success.svg");
    }
  }

  &.info {
    background-color: map-get($foundation-palette, info);

    &:before {
      background-image: url("./img/info.svg");
    }
  }

  &.warning {
    background-color: map-get($foundation-palette, warning);

    &:before {
      background-image: url("./img/warning.svg");
    }
  }

  &.error {
    background-color: map-get($foundation-palette, alert);

    &:before {
      background-image: url("./img/error.svg");
    }
  }
}

.sys-messages__message {
  flex: 1;
  margin-bottom: 0;
}
