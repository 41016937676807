$unsubscribe-padding: 1rem;

.category-wrapper {
  .forms-wrapper {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;

    margin: 1.5rem 0 4rem;

    .category-search,
    .sort--category {
      margin-bottom: 1rem;
    }

    @include breakpoint(medium up) {
      flex-flow: row wrap;
      justify-content: space-between;
    }
  }
}

.thread-list__sort-info {
  font-family: $changa;
  font-weight: 700;
}

.category-wrapper__header {
  @extend %text-center-small-only;
}

.thread-list {
  margin: 0 0 1rem 0;
  list-style-type: none;
  border-color: $black;

  @each $category, $color in $category-colors {
    &--#{$category} {
      border-color: $color;
    }
  }

  @include breakpoint(medium up) {
    margin-bottom: 2rem;
  }
}

.thread-list__item {
  padding: 2.5rem 0 1rem;
  border-bottom: rem-calc(4px) solid;
  border-color: inherit;

  &--empty {
    margin-top: 1rem;
    text-align: center;
    border-bottom: none;
  }

  &:first-child {
    padding-top: 0;
  }
}

.thread-list__title {
  font-size: 1.5rem;

  a {
    &:hover,
    &:focus {
      color: lighten($black, 30%);
    }
  }

  @include breakpoint(medium up) {
    font-size: 1.75rem;
  }
}

.thread-list__author,
.thread-list__meta {
  font-weight: 700;
}

.thread-list__unsubscribe {
  position: relative;

  padding: 1rem 0;
  margin-bottom: 1rem;
}

.thread-list__status {
  position: absolute;
  right: 0;
  width: 100%;
  text-align: center;

  font-family: $changa;
  font-weight: 700;

  visibility: hidden;
  opacity: 0;
  transition:
    visibility ease 0.3s,
    opacity ease 0.3s;

  p {
    margin-bottom: 0;
  }

  .thread-list__item--unsubscribed & {
    visibility: visible;
    opacity: 1;
  }

  @include breakpoint(medium up) {
    text-align: right;
  }
}

.thread-list__button {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  top: calc($unsubscribe-padding / 2);

  opacity: 1;
  visibility: visible;

  transition:
    visibility ease 0.3s,
    opacity ease 0.3s;

  .thread-list__item--unsubscribed & {
    visibility: hidden;
    opacity: 0;
  }

  @include breakpoint(medium up) {
    right: 0;
    transform: translateX(0);
  }
}

.thread-list__add {
  text-align: center;

  margin-bottom: 1rem;

  @include breakpoint(medium up) {
    margin-bottom: 2rem;
  }

  &--top {
    @include breakpoint(medium up) {
      text-align: left;
    }
  }
}

$form-height: 2.25rem;
$form-border-radius: rem-calc(4px);

.category-search {
  display: flex;
}

.category-search__input {
  display: inline-block;
  height: $form-height;
  margin: 0;
  padding: 0 0.5rem;

  border: 2px solid $black;
  border-right: none;
  border-radius: $form-border-radius 0 0 $form-border-radius;
}

.category-search__button {
  display: flex;
  align-items: center;

  height: $form-height;
  margin: 0;

  border-radius: 0 $form-border-radius $form-border-radius 0;
  background-color: $black;

  background: url("img/search_icon.svg") center / 2rem no-repeat $black;
  transition: opacity ease 0.3s;
  width: 2.75rem;

  &:hover,
  &:focus {
    opacity: 0.7;
  }
}
