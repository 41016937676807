/*
* General Form Styles
* ===================
*/

$form-border-radius: rem-calc(4px);
$form-border-width: rem-calc(3px);

$select-background-size: 1rem;
$select-padding-right: 2.5rem;
$select-background-pos-right: calc(
  ($select-padding-right / 2) - ($select-background-size / 2)
);

$sort-height: 2.25rem;

// Form elements
// =============

label {
  font-family: $changa;
  font-weight: 700;

  li & {
    display: inline;
  }
}

.helptext {
  font-size: 0.9rem;
  font-style: italic;
}

input {
  padding: 0.5rem 1rem;

  font-family: $changa;
  font-weight: 700;

  border: 0;
  border: $form-border-width solid $black;
  border-radius: 0.25rem;
}

select {
  margin: 0;
  padding: 0.25rem $select-padding-right 0.25rem 1rem;

  font-size: inherit;
  font-family: $changa;
  font-weight: 700;

  appearance: none;
  border: 0;
  border-radius: $form-border-radius;
  border: $form-border-width solid $black;

  background-color: $white;
  background-image: url("./img/arrow-black.svg");
  background-repeat: no-repeat;
  background-size: $select-background-size;
  background-position: right $select-background-pos-right top 50%;

  &::-ms-expand {
    display: none; /* hide the default arrow in ie10 and ie11 */
  }
}

form {
  ul {
    list-style-type: none;
    margin-bottom: 1rem;
  }

  .form__list {
    list-style-type: inherit;
    padding-left: 1.25rem;
  }
}

// Form wrapper
// =============

.form {
  position: relative;

  width: 100%;
  padding: 1rem;
  margin: 0 auto;

  border: $form-border-width solid $black;
  border-radius: $form-border-radius;

  background-color: $white;

  input[type="text"],
  input[type="email"],
  input[type="password"],
  select,
  textarea {
    width: 100%;
  }

  button.boxed {
    width: 100%;
  }

  &--login {
    button.boxed {
      margin-bottom: 1rem;
    }
  }
}

.form__registration-links {
  display: flex;
  justify-content: space-between;
}

.form__button-group {
  display: flex;
  justify-content: space-between;

  > *:first-child {
    flex: 1;
    margin-right: 0.5rem;
  }
}

.form__terms {
  display: flex;
  align-items: flex-start;

  input {
    margin-right: 1rem;
    margin-top: 0.4rem;
  }

  p {
    flex: 1;
  }
}

.form-list {
  margin: 0;

  > li {
    margin-bottom: 1rem;
  }
}

// Avatar List
// ===========

.avatar-gallery {
  position: relative;
  overflow: hidden;
  width: 100%;

  @include breakpoint(medium up) {
    overflow: visible;
  }
}

.avatar-gallery__arrows {
  @include breakpoint(medium up) {
    display: none;
  }
}

.avatar-gallery__arrow {
  position: absolute;
  top: 50%;

  display: block;
  width: 1.5rem;
  height: 1.5rem;

  background: url(./img/arrow-black.svg) center / contain no-repeat;

  &--prev {
    left: 0;
    transform: translateY(-50%) rotate(90deg);
  }

  &--next {
    right: 0;
    transform: translateY(-50%) rotate(-90deg);
  }
}

.avatars {
  margin-bottom: 1rem;
  display: flex;
  transition: transform 0.3s ease-in-out;

  @include breakpoint(medium up) {
    @include flex-grid-row();
    @include flex-grid-layout(6, ".avatars__item");
  }

  @include breakpoint(large up) {
    @include flex-grid-layout(7, ".avatars__item");
  }
}

.avatars__item {
  position: relative;
  list-style-type: none;

  line-height: 0;
  font-size: 1rem;

  transform-origin: center;
  transition: transform ease 0.3s;

  @include breakpoint(medium up) {
    &:hover {
      z-index: 2;
      transform: scale(2);
    }
  }
}

.avatars__label {
  display: block;
  padding: 0.5rem;
  cursor: pointer;
  text-align: center;

  img {
    pointer-events: none;
  }
}

.avatars__input:checked ~ .avatars__label img,
.avatars__label:hover imgm .avatars__label:focus img {
  outline: $form-border-width $tawny-port solid;
  outline-offset: -$form-border-width;
}

.avatars__input:focus ~ .avatars__label {
  box-shadow: 0 0 4px 4px $mariner;
}

.avatars__input {
  @extend %visuallyhidden;
}

// Errorlists
// ==========

.errorlist,
.error {
  margin: 0 0 0.5rem 0;
  padding: 0.5rem 1rem;

  background-color: map-get($foundation-palette, alert);
  border-radius: $form-border-radius;

  font-family: $roboto;
}

.errorlist {
  list-style-type: none;

  &.nonfield {
    margin-bottom: 1rem;
  }
}

.error {
  p {
    margin-bottom: 0;
  }
}

// Sort Select
// ===========

.sort {
  display: flex;
}

.sort__select {
  height: $sort-height;
  margin: 0;
  padding: 0 2.5rem 0 0.5rem;
  padding-top: 0;
  padding-bottom: 0;

  border: 2px solid $black;
}

.sort__button {
  height: $sort-height;
  padding: 0 0.5rem;
  margin: 0;

  color: $white;
  font-family: $changa;
  font-weight: 700;

  background-color: $black;
  border: 0;
  border-radius: 0 $form-border-radius $form-border-radius 0;

  &:hover,
  &:focus {
    background-color: lighten($black, 20%);
  }
}

// Post Form
// ===========

.django-ckeditor-widget {
  width: 100%;
  margin-bottom: 1rem;
}

.cke_dialog {
  .cke_dialog_tab {
    min-width: 0;
    padding-left: 6px;
    padding-right: 6px;
  }

  a[data-unicode] {
    font-family: $ms-emoji;
    font-size: 1.2rem;

    @include breakpoint(medium up) {
      font-size: 1.75rem;
    }
  }
}

// Loading Spinner
// ===============

@keyframes fade {
  0% {
    background-color: $mandy;
    transform: scale(1);
  }

  16.66% {
    background-color: $fuel-yellow;
  }

  33.33% {
    background-color: $rio-grande;
  }

  50% {
    background-color: $scooter;
    transform: scale(0.5);
  }

  66.66% {
    background-color: $denim;
  }

  83.33% {
    background-color: $hawaiian-tan;
  }

  100% {
    background-color: $mandy;
    transform: scale(1);
  }
}

.spinner {
  visibility: hidden;
  opacity: 0;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;

  width: 100%;
  height: 100%;

  background-color: rgba($black, 0.5);

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  transition: opacity 0.3s ease;

  .form--loading & {
    visibility: visible;
    opacity: 1;
  }
}

.spinner__circle {
  display: inline-block;
  position: relative;

  width: 2rem;
  height: 2rem;
  margin: 0 0.5rem;

  border-radius: 50%;

  .form--loading & {
    animation: fade 5s infinite;

    &:nth-child(2) {
      animation-delay: 0.3s;
    }

    &:nth-child(3) {
      animation-delay: 0.6s;
    }
  }
}
