$accordion-arrow-length: 1rem;
$accordion-padding: $accordion-arrow-length + 1rem;

.accordion {
  & + .content-richtext {
    margin-top: 2rem;
  }
}

.accordion__title {
  position: relative;
  display: inline-block;
  padding-left: $accordion-padding;

  font-size: $body-font-size;
  font-family: $roboto;
  line-height: $paragraph-line-height;

  cursor: pointer;

  &[aria-expanded="true"] {
    + .accordion__content {
      display: block;
    }

    &::before {
      transform: rotate(-90deg);
    }
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0.25em;

    display: inline-block;
    height: $accordion-arrow-length;
    width: $accordion-arrow-length;

    background: url("./img/arrow-black.svg") center / contain no-repeat;
  }

  @include breakpoint(medium up) {
    font-size: $body-font-size-medium;
  }
}

.accordion__content {
  display: none;
  padding-left: $accordion-padding;
}
