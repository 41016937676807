$black: #000000;
$white: #ffffff;
$alabaster: #f7f7f7;
$dove-gray: #666666;
$silver: #c9c9c9;
$golden-dream: #f0d52d;

// Focus color
$mariner: #2876c5;

// Teaser colors:
$tawny-port: #60283f;
$abbey: #4c4d57;

// Colors of the categories:
// Love and relationship
$mandy: #e75f5f;
// Health
$fuel-yellow: #f0a830;
// Money & Apartment
$rio-grande: #c8cf02;
// Parents
$scooter: #47cdd8;
// Job & Education
$hawaiian-tan: #8a6614;
// Hobbies
$denim: #0c66b0;

// $category-colors: (
//   'love': $mandy,
//   'health': $fuel-yellow,
//   'money': $rio-grande,
//   'parents': $scooter,
//   'job': $hawaiian-tan,
//   'hobbies': $denim
// );

// German categories
$category-colors: (
  "liebe-freundschaft": $mandy,
  "gesundheit": $fuel-yellow,
  "geld-wohnung": $rio-grande,
  "elternhaus": $scooter,
  "job-ausbildung": $hawaiian-tan,
  "hobbys": $denim,
);
