.teaser-container {
  margin-bottom: 2rem;
}

.question-teaser {
  // height: 100%;
  padding: 1rem;

  border: rem-calc(4px) solid $black;
  border-radius: rem-calc(8px);

  p {
    margin-bottom: 0;
  }

  hr {
    width: 100%;
    margin: 1rem 0;

    border: rem-calc(2px) $silver solid;
  }
}

.question-teaser__title {
  @extend %hyphenate;
  word-wrap: break-word;

  @include breakpoint(medium up) {
    font-size: 2.25rem;
  }
}

// Footer
// ======

.question-teaser__footer {
  display: flex;
  flex-wrap: wrap;
}

.question-teaser__author,
.question-teaser__time,
.question-teaser__answers {
  flex: 1 1 auto;
}

.question-teaser__author {
  font-family: $changa;
  font-weight: 700;
}

.question-teaser__time {
  color: $abbey;
}

.question-teaser__answers {
  color: $abbey;
}

// List with question teasers of a category
// ========================================

.category-preview-section {
  &:nth-child(2n) {
    > .row {
      flex-direction: row-reverse;
    }
  }

  @each $category, $color in $category-colors {
    &.#{$category} {
      .speech-bubble {
        background-color: $color;
        border-color: $color;
      }

      .question-teaser-list__item {
        border-color: $color;
      }
    }
  }
}

.category-preview-section__title-box {
  position: relative;
  margin-bottom: 2rem;

  h2 {
    margin-bottom: 1rem;

    @include breakpoint(medium up) {
      margin-bottom: 1.5rem;
    }
  }

  .speech-bubble {
    position: absolute;
    color: $white;

    &--right--small {
      bottom: 0.5rem;
      left: 2rem;
    }

    &--left--small {
      bottom: 0.5rem;
      right: 2rem;
    }

    a {
      color: $white;
      padding-right: 1.5rem;

      background-image: url(./img/arrow.svg);
      background-size: 1.5rem;
      background-repeat: no-repeat;
      background-position: right 0px top 50%;

      transition: opacity 0.3s ease;

      &:hover,
      &:focus {
        opacity: 0.7;
      }
    }
  }

  @include breakpoint(large up) {
    margin-bottom: 0;
  }
}

.question-teaser-list {
  list-style-type: none;
  margin: 0;
}

.question-teaser-list__item {
  border-bottom: rem-calc(4px) solid black;
  padding: 1rem 0 1rem;

  h3 {
    margin-bottom: 0.25rem;

    font-size: 1.5rem;
    line-height: 1.1;
  }

  &:first-child {
    padding-top: 0;
  }
}

.question-teaser-list__item-footer {
  font-size: 1rem;
}

.question-teaser-list__author,
.question-teaser-list__answers {
  font-weight: bold;
}

// Tip teasers
// ===========

.tip-box-section {
  h2 {
    margin: 0 0 0 2rem;
  }

  header {
    margin-bottom: 2rem;
  }
}

.tip-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .tip-teaser {
    &:nth-child(2n-1) {
      transform: translateX(0.5rem);
    }
    &:nth-child(2n) {
      transform: translateX(-0.5rem);
    }

    @include breakpoint(medium up) {
      // translate values for 2 boxes
      // &:nth-child(1) { transform: translate(0rem, 0rem)}
      // &:nth-child(2) { transform: translate(0rem, .5rem)}

      // translate values for 4 boxes
      &:nth-child(1) {
        transform: translate(0rem, -1rem);
      }
      &:nth-child(2) {
        transform: translate(0.5rem, 0.5rem);
      }
      &:nth-child(3) {
        transform: translate(-0.5rem, 0rem);
      }
      &:nth-child(4) {
        transform: translate(0rem, 1rem);
      }
    }
  }
}

.tip-teaser {
  width: 100%;
  padding: 0.5rem 1rem;

  p {
    color: $white;
    margin-bottom: 0;
  }

  @each $category, $color in $category-colors {
    &.#{$category} &__box {
      background-color: $color;
    }
  }

  @include breakpoint(medium up) {
    width: 50%;
    padding: 1rem;
  }

  &--video {
    text-align: center;

    @include breakpoint(medium up) {
      width: auto;
    }
  }
}

.tip-teaser__box {
  padding: 1rem;
  border-radius: rem-calc(8px);
  text-align: center;

  font-size: 1rem;
}

.tip-teaser__title {
  color: $white;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.tip-container__button {
  display: flex;
  justify-content: center;
  align-items: baseline;

  width: 100%;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;

  @include breakpoint(medium up) {
    justify-content: flex-end;
  }
}

// Chat teaser
// ===========

.chat-teaser {
  .color-bar {
    margin-bottom: 2rem;
  }
}

.chat-teaser__image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &:before {
    content: "";
    float: left;
    padding-top: 60%;
  }

  @include breakpoint(medium up) {
    &:before {
      content: none;
    }
  }
}

.chat-teaser__time,
.chat-teaser__host {
  font-family: $changa;
  font-weight: 700;
  font-size: 1.2rem;

  @include breakpoint(medium up) {
    font-size: 1.3rem;
  }
}

.chat-teaser__host {
  display: inline-block;
  margin-bottom: 1rem;
}

.chat-teaser__content {
  margin-bottom: 1rem;

  @include breakpoint(medium up) {
    margin-bottom: 1.5rem;
  }
}

.chat-teaser__button {
  display: flex;
  flex-flow: row;

  margin-bottom: 2rem;
}
