$pagination-padding: 0.1875rem 0.625rem;
$pagination-color: $black;
$pagination-hover-color: lighten($pagination-color, 40%);

.pagination {
  list-style-type: none;
  margin: 4rem 0 0 0;

  font-family: $changa;
  text-align: center;
  font-weight: 700;

  .disabled {
    background-color: $pagination-hover-color;
    padding: $pagination-padding;
  }
}

.pagination__item {
  display: inline-block;
  margin-bottom: 0.25rem;

  background-color: $pagination-color;
  color: $white;

  border-radius: rem-calc(4px);
  transition: 0.3s ease-in-out;

  a {
    display: inline-block;
    padding: $pagination-padding;
    color: white;
  }

  &:hover,
  &:focus {
    background-color: $pagination-hover-color;
  }
}

.pagination__ellipsis {
  background-color: transparent;

  &:after {
    content: "\2026";
    color: $pagination-color;
    // background-color: transparent;
    padding: $pagination-padding;
  }

  &:hover,
  &:focus {
    background-color: transparent;
  }
}

.pagination__current {
  padding: $pagination-padding;
  background-color: $pagination-hover-color;
}

.pagination__previous {
  a {
    &:before {
      content: "\00ab";
    }
  }

  &.disabled {
    &:before {
      content: "\00ab";
    }
  }
}

.pagination__next {
  a {
    &:after {
      content: "\00bb";
    }
  }

  &.disabled {
    &:after {
      content: "\00bb";
    }
  }
}
