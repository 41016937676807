// http://lea.verou.me/2011/08/accessible-star-rating-widget-with-pure-css/

.rating {
  border: none;
  padding: 0 0 0 0.5rem;

  > label {
    // height: rem-calc(27px);
    line-height: 1;
    color: darken($alabaster, 30%);
    cursor: pointer;

    transition: color 0.1s;

    &::before {
      content: "\2605";
      font-size: rem-calc(22px);
    }
  }

  /* :not(:checked) is a filter, so that browsers that don’t support :checked don’t
   follow these rules. Every browser that supports :checked also supports :not(), so
   it doesn’t make the test unnecessarily selective */
  &:not(:checked) {
    & > input {
      @extend %visuallyhidden;
    }

    & > label {
      float: right;
    }
  }

  & > input:checked ~ label {
    color: $golden-dream;
  }

  &:not(:checked) > label:hover,
  &:not(:checked) > label:hover ~ label,
  &:not(:checked) > label:focus,
  &:not(:checked) > label:focus ~ label {
    color: lighten($golden-dream, 10%);
  }

  & > input:checked + label:hover,
  & > input:checked + label:hover ~ label,
  & > input:checked ~ label:hover,
  & > input:checked ~ label:hover ~ label,
  & > label:hover ~ input:checked ~ label,
  & > input:checked + label:focus,
  & > input:checked + label:focus ~ label,
  & > input:checked ~ label:focus,
  & > input:checked ~ label:focus ~ label,
  & > label:focus ~ input:checked ~ label {
    color: ligthen($golden-dream, 10%);
  }
}
