$border-radius: 0.25rem;

.gallery {
  position: relative;
  margin-bottom: 1rem;

  h2 {
    font-size: rem-calc(21);

    @include breakpoint(medium up) {
      font-size: rem-calc(25);
    }
  }
}

.gallery__slides {
  overflow: hidden;
}

.gallery__slider {
  transition: transform 0.5s ease-in-out;
  display: flex;
}

.gallery__slide {
  color: white;
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &::before {
    display: block;
    content: "";
    width: 100%;
    padding-bottom: 62.5%;
  }
}

.gallery__slide-description {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  margin: 0.5rem;
  padding: 0.5rem 1rem;

  background-color: transparentize($white, 0.05);
  color: $black;

  & > :last-child {
    margin-bottom: 0;
  }
}

$control-size: 2rem;
$control-size: 2rem;

$control-size-medium: 3rem;
$control-size-medium: 3rem;

.gallery__go {
  position: absolute;
  display: block;
  top: 0;
  height: 100%;
  width: $control-size;

  outline: none;

  display: flex;
  align-items: center;

  @include breakpoint(medium up) {
    width: $control-size-medium;
  }

  &::before {
    content: "";
    display: block;

    height: $control-size;
    width: $control-size;

    background: $white 50% 65% / 50% no-repeat;
    border-radius: $border-radius $border-radius 0 0;

    transition: background-color 0.25s ease-in;

    @include breakpoint(medium up) {
      height: $control-size-medium;
      width: $control-size-medium;
      background-position: center;
    }
  }

  &:hover::before {
    background-color: transparentize($white, 0.2);
  }

  &[data-go="-1"] {
    // 0.5px is used to avoid a thin gap
    left: -rem-calc(0.5);

    &::before {
      transform: rotate(90deg);
      background-image: url("img/arrow-black.svg");
    }
  }

  &[data-go="1"] {
    // 0.5px is used to avoid a thin gap
    right: -rem-calc(0.5);

    &::before {
      transform: rotate(-90deg);
      background-image: url("img/arrow-black.svg");
    }
  }
}

.gallery__go {
  visibility: hidden;
  [data-hide-controls="false"] & {
    visibility: visible;
  }
}
