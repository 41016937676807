// DEV / ADMIN
// ===========

#djDebugToolbarHandle {
  top: 400px !important;
}

.edit-tools {
  position: fixed;
  bottom: 0.25rem;
  right: 3rem;
  z-index: 100;
}

.show-breakpoint {
  position: fixed;
  bottom: 2.6rem;
  right: 0;
  font-family: $changa;

  div {
    padding: 1rem;
  }

  .show-for-small-only {
    background-color: rgba(0, 191, 251, 0.7);
  }

  .show-for-medium-only {
    background-color: rgba(0, 255, 175, 0.7);
  }

  .show-for-large {
    background-color: rgba(228, 41, 41, 0.7);
  }
}

// Helper Classes and Mixins
// =========================

%visuallyhidden {
  position: absolute;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);

  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
}

.visuallyhidden {
  @extend %visuallyhidden;
}

@mixin visuallyhidden {
  position: absolute;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);

  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
}

// General Styles
// ==============

body {
  background-color: $alabaster;

  &.open-nav {
    overflow: hidden;
  }
}

main {
  padding: 2rem 0;

  @include breakpoint(medium up) {
    padding: 4rem 0;
  }

  .no-mood & {
    padding: 0 0 2rem 0;

    @include breakpoint(medium up) {
      padding: 4rem 0;
    }
  }
}

.content-html {
  .table-wrapper {
    overflow: auto;
  }
}

/* Sticky Footer */

body {
  display: flex;
  margin: 0;
}

.page-wrapper {
  display: flex;
  flex: 1 1 auto;

  flex-flow: column nowrap;
  min-height: 100vh;

  overflow: hidden;
}

main {
  flex: 1 1 auto;

  // IE11 Height fix
  min-height: 1px;
}

/* Jump to top */

.scroll-top {
  position: fixed;
  bottom: 0.25rem;
  right: 0.25rem;

  width: 2rem;
  height: 2rem;

  background: url(./img/arrow.svg) 25% center / 1.5rem $black no-repeat;
  border-radius: 0.25rem;
  transform: rotate(270deg);

  opacity: 0;
  visibility: hidden;
  transition:
    0.3s ease opacity,
    0.3s ease visibility;

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

// Focus
// =====

%focus-outline {
  outline: rem-calc(3px) $mariner solid;
  outline-offset: rem-calc(1px);
  transition: outline 0s;
}

a {
  &:focus {
    text-decoration: underline;
  }
  &:active {
    text-decoration: none;
  }
}

input:focus,
select:focus {
  outline: none;
  box-shadow: 0 0 5px 2px $mariner;

  .header &,
  .footer & {
    box-shadow: 0 0 5px 2px lighten($mariner, 20%);
  }
}

// Startpage
// =========

.home {
  main {
    padding: 0;
  }
}

.alternating-background {
  background-color: $white;
  padding: 4rem 0;

  &:nth-child(2n) {
    background-color: $alabaster;
  }
  .description__button {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
}

// Introducion Section (Contains teasers and an intro text)
// ========================================================

.introduction-section {
  background-color: $alabaster;
  padding: 1.5rem 0 0;

  .description {
    margin-bottom: 2rem;

    @include breakpoint(large up) {
      margin-bottom: 0;
    }
  }

  @include breakpoint(medium up) {
    padding-bottom: 4rem;
  }
}

// Color bar
// =========

.color-bar {
  display: flex;
  flex-direction: row wrap;
}

.color-bar__tile {
  height: 1rem;
  width: calc(100% / 6);

  @each $category, $color in $category-colors {
    $i: index(($category-colors), ($category $color));

    &:nth-child(#{$i}) {
      background-color: $color;
    }
  }
}

// Link / Buttons with and without Icons
// =====================================

a.boxed,
button.boxed,
.icon-link {
  display: inline-block;
  padding: 0.35rem 0.9rem;

  border-radius: rem-calc(4px);
  background-color: $black;

  color: $white;
  font-family: $changa;
  font-weight: 700;

  &:hover,
  &:focus {
    background-color: lighten($black, 20%);
  }

  &--alert {
    background-color: map-get($foundation-palette, alert);

    &:hover,
    &:focus {
      background-color: lighten(map-get($foundation-palette, alert), 10%);
    }
  }
}

.boxed[aria-expanded="true"] {
  background-color: $dove-gray;
}

.icon-link {
  padding: 0.75rem;

  background-repeat: no-repeat;
  background-size: 65%;
  background-position: center;

  @include breakpoint(medium up) {
    padding: 1rem;
  }
}

.icon-link--disabled {
  background-color: $silver;

  &:hover {
    background-color: $silver;
  }
}

.icon-link--add {
  background-image: url(./img/user-plus.svg);
}

.icon-link--message {
  background-image: url(./img/envelope.svg);
}

.icon-link--read {
  background-image: url(./img/envelope-open.svg);
}

.icon-link--close {
  background-image: url(./img/close.svg);
}

.icon-link--check {
  background-image: url(./img/check.svg);
}

// Donwload link
.download {
  margin-bottom: 1rem;
  // background-image: url(./img/download.svg);
}

.back-link {
  display: inline-block;
  margin-bottom: 2rem;

  text-transform: uppercase;
  font-family: $changa;
  font-weight: 700;

  &--large {
    @include breakpoint(medium up) {
      margin-bottom: 4rem;
    }
  }
}

// Standard CMS Styling
// ====================

.standard-content {
  padding-top: 1rem;
  padding-bottom: 1rem;

  background-color: $white;
  border-radius: rem-calc(4px);

  img {
    margin-bottom: 1rem;
  }
}

// Checkmark list
$checkmark-icon-width: 1rem;
$checkmark-list-padding: 1.25rem;
.checkmark-list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 1rem;

  li {
    background: url("img/checkmark.svg") no-repeat left top / 1.575rem;
    padding-left: $checkmark-list-padding + $checkmark-icon-width;
  }
}

.f3cc {
  --f3cc-accept-background: #{$mandy};
  --f3cc-background: #{$alabaster};
  --f3cc-foreground: #{$tawny-port};
  --f3cc-button-background: #{$silver};
  --f3cc-button-foreground: #{$white};
  --f3cc-accept-foreground: #{$white};

  .f3cc-banner {
    left: 0;
  }

  .f3cc-button {
    border-radius: 0.5rem;
  }

  .f3cc-button.modify {
    bottom: 0.25rem !important;
    right: 2.75rem !important;
    height: 2rem;
    padding: 0.625rem 1.2rem;
    font-size: 12px;
    border-radius: 0.25rem;
  }
}
