// Profile
// =======

.profile__title {
  @extend %text-center-small-only;
  margin-bottom: 2rem;
  font-size: 1.5rem;

  @include breakpoint(medium up) {
    margin-bottom: 1.5rem;
  }
}

.profile__avatar {
  display: block;
  max-width: 40%;
  margin: 0 auto 1rem;

  @include breakpoint(medium up) {
    max-width: 50%;
    margin: 0 0 1rem;
  }

  @include breakpoint(large up) {
    max-width: 44%;
  }
}

.profile__meta {
  @extend %text-center-small-only;

  display: flex;
  flex-flow: wrap row;

  > div {
    flex-basis: 100%;
  }

  dt {
    display: inline-block;
    margin: 0;
    font-weight: 700;

    &:after {
      content: ":";
    }
  }

  dd {
    display: inline-block;
    margin: 0;
  }
}

.profile__link-group {
  @extend %text-center-small-only;

  margin-bottom: 1rem;

  a {
    margin-bottom: 0.5rem;
  }

  @include breakpoint(medium up) {
    margin-bottom: 0;

    display: flex;
    flex-direction: column;

    a {
      text-align: center;
    }
  }

  @include breakpoint(large up) {
    flex-direction: row;

    > a:first-child {
      flex: 1;
      margin-right: 0.5rem;
    }
  }
}

.profile-nav {
  list-style-type: none;
  margin-bottom: 2rem;

  @include breakpoint(medium up) {
    margin-bottom: 0;
  }
}

$nav-item-border-width: rem-calc(2px);

.profile-nav__item {
  position: relative;
  padding: 1rem 0;
  border-bottom: $nav-item-border-width solid $black;
}

.profile-nav__header {
  display: flex;
  justify-content: space-between;
  // margin-bottom: .25rem;
}

.profile-nav__title {
  font-size: 1.5rem;
  margin-bottom: 0;
}

// Friend Requests
// ===============

.friend-requests {
  padding-top: 2rem;

  .errorlist {
    &[aria-hidden="true"] {
      display: none;
    }
  }
}

.friend-requests__title {
  font-size: 1.2rem;
}

.friend-requests__list {
  list-style-type: none;
}

.friend-request {
  display: flex;
  align-items: center;

  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.friend-request__image {
  display: block;
  width: 3rem;
}

.friend-request__info {
  display: flex;
  flex-direction: column;

  flex: 1;

  padding: 0 0.5rem;
  font-size: $body-font-size;
}

.friend-request__name {
  font-size: inherit;
  margin-bottom: 0;
}

.friend-request__meta {
  margin: 0;

  dt,
  dd {
    margin: 0;
  }

  dd {
    display: inline;
    font-size: 90%;

    &::after {
      content: ", ";
    }

    &:last-child {
      &::after {
        content: "";
      }
    }
  }
}

.friend-request__forms {
  display: flex;

  form {
    margin: 0 0.25rem;
  }
}
