// Tips Filter
// ===========

.tips__filter-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  margin-bottom: 1rem;
  padding-bottom: 0.5rem;

  list-style-type: none;
}

.tips__filter {
  display: inline-block;
  padding: 0.25rem 0.25rem;

  font-family: $changa;
  font-weight: 700;

  a,
  span.active {
    display: inline-block;
    width: 100%;
    padding: 0.25rem 0.5rem;

    background-color: $black;
    border-radius: 0.25rem;

    color: $white;
    text-align: center;

    &:hover,
    &:focus {
      background-color: $tawny-port;
    }
  }

  span.active {
    background-color: $tawny-port;
  }
}

// Tips List
// =========

.tips__list {
  list-style-type: none;

  column-count: none;
  column-gap: 1.5rem;

  @include breakpoint(medium up) {
    column-count: 2;
  }

  @include breakpoint(large up) {
    column-count: 3;
  }
}

.tips__list--empty {
  column-count: 1;
}

.tips__item {
  margin-bottom: 1.5rem;
  border-radius: rem-calc(8px);
  text-align: center;

  /* inline-block prevents the element from being split up in 3 columns if it is the only item */
  display: inline-block;
  break-inside: avoid;

  @each $category, $color in $category-colors {
    $i: index(($category-colors), ($category $color));

    &--#{$category} {
      background-color: $color;
    }
  }

  p {
    color: $white;
  }
}

.tips__item--empty {
  p {
    color: $black;
  }
}

.tips__link {
  display: block;
  padding: 1rem;
}

.tips__category {
  display: inline-block;
  margin-bottom: 0.5rem;

  color: $white;
  font-family: $changa;
  font-size: 1rem;
}

.tips__title {
  font-size: 1.5rem;
  color: $white;
}

.tips__image {
  margin-bottom: 0.75rem;
}

// Tip Detail
// ==========

.tip__category {
  display: inline-block;
  font-size: 1rem;
  font-family: $changa;

  @include breakpoint(medium up) {
    font-size: 1.25rem;
  }
}

.tip__title {
  margin-bottom: 2rem;
}
