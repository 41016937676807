// Search form
// ===========

.search-results__form {
  margin-bottom: 2rem;

  display: flex;
  justify-content: center;

  input {
    height: 2.25rem;
    padding: 0 0.5rem;
    border: 2px solid $black;
    border-radius: 0.25rem 0 0 0.25rem;
  }

  button {
    width: 2.75rem;
    border-radius: 0 0.25rem 0.25rem 0;
    background: url("img/search_icon.svg") center / 2rem no-repeat $black;
    transition: opacity ease 0.3s;

    &:hover {
      opacity: 0.7;
    }
  }

  @include breakpoint(medium up) {
    margin-bottom: 4rem;
    justify-content: flex-start;
  }
}

// Result list
// ===========

.search-results__list {
  list-style-type: none;
}

// Result detail
// =============

.search-result {
  padding: 1rem;
  background-color: $white;

  border-bottom: 3px solid $black;

  &:nth-child(2n) {
    background-color: transparent;
  }

  &:first-child {
    border-top: 3px solid black;
  }
}

.search-result__header {
  display: flex;
  flex-direction: column-reverse;

  @include breakpoint(medium up) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.search-result__title {
  font-size: $body-font-size;
  line-height: 1.3;

  @include breakpoint(medium up) {
    font-size: $body-font-size-medium;
  }
}

.search-result__forum-label {
  display: inline-block;

  color: $white;
  font-family: $changa;
  font-weight: 700;
  font-size: 0.9rem;

  padding: 0.25rem;
  margin-bottom: 0.5rem;

  @each $category, $color in $category-colors {
    &--#{$category} {
      background-color: $color;
    }
  }

  @include breakpoint(medium up) {
    padding: 0.25rem 0.5rem;
    margin-bottom: 0;
  }
}
