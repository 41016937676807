.sidebar {
  background-color: $white;
  margin-top: 2.5rem;

  @include breakpoint(large up) {
    margin-top: 0;
  }
}

.sidebar__box {
  padding: 2rem 1.5rem;
  border-bottom: 0.125rem solid $black;

  @include breakpoint(medium up) {
    &:first-child {
      margin-top: 0rem;
    }
  }
}

.sidebar__tip {
  padding: 1rem;
  margin-bottom: 1rem;

  text-align: center;
  border-radius: rem-calc(8px);

  h3 {
    color: $white;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }

  p {
    margin-bottom: 0;
    color: $white;
    font-size: 1rem;
  }

  @each $category, $color in $category-colors {
    &--#{$category} {
      background-color: $color;
    }
  }
}
