@import "~foundation-sites/scss/util/util";

$global-width: rem-calc(1100);

$grid-column-gutter: (
  small: 20px,
  medium: 30px,
);

$foundation-palette: (
  primary: #1779ba,
  secondary: #767676,
  success: #3adb76,
  warning: #ffae00,
  alert: #cc4b37,
  info: #c0e0fb,
);

@import "~foundation-sites/scss/foundation";

$global-flexbox: true;

@include foundation-flex-grid;
@include foundation-responsive-embed;
@include foundation-flex-classes;
@include foundation-visibility-classes;
@include foundation-float-classes;
