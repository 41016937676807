.friend-list {
  margin-bottom: 2rem;

  @include breakpoint(medium up) {
    margin-bottom: 4rem;
  }
}

.request-friendlist-title {
  font-size: rem-calc(map-get(map-get($responsive-sizes, small), h3));

  @include breakpoint(medium up) {
    font-size: rem-calc(map-get(map-get($responsive-sizes, medium), h3));
  }
}

.friend-list__item {
  list-style: none;
}

.friend {
  position: relative;

  display: flex;
  flex-direction: column;
}

.friend__avatar {
  order: 1;
  margin-bottom: 1rem;
  // IE workaround:
  flex-shrink: 0;
}

.friend__title {
  text-align: center;
  font-size: 1.2rem;

  order: 2;
}

.friend__unfriend {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

// Dialog Modal
// ============

.friend-list-dialog {
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  background-color: rgba(0, 0, 0, 0.85);

  visibility: visible;
  opacity: 1;
  transition:
    opacity 0.3s ease,
    visibility 0.3s ease;
  will-change: opacity;

  &[aria-hidden="true"] {
    visibility: hidden;
    opacity: 0;
  }

  .errorlist {
    &[aria-hidden="true"] {
      display: none;
    }
  }

  .text--request {
    display: none;
  }

  &.friend-request {
    .text--default {
      display: none;
    }

    .text--request {
      display: block;
    }
  }
}

.friend-list-dialog__wrapper {
  flex: 1;
}

.friend-list-dialog__content {
  padding: 1rem;
  background-color: $white;
  border-radius: 0.25rem;
}

.friend-list-dialog__nickname {
  font-family: $changa;
  font-weight: 700;
}

.friend-list-dialog__buttons {
  text-align: right;
}
